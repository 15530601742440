import styled from "styled-components"

export const MarkerInfo = styled.div`
  color: var(--darkBlue);
  padding: 5px;

  .icon {
    margin-right: 10px;
    margin-bottom: 0;
  }

  div {
    margin-bottom: 20px;
  }

  p {
    margin: 5px;
  }
`

export const SearchContainer = styled.div`
  width: 20%;
  height: 100%;
  overflow-y: scroll;

  input {
    padding: 5px;
    width: 100%;
    font-size: 0.8rem;
    border: 1px solid var(--beige);

    &:focus {
      outline: none;
      color: var(--darkBlue);
    }
  }

  h5 {
    margin: 2px 0;
  }

  h6 {
    font-weight: lighter;
  }

  ul {
    margin: 0;
    /* background-color: var(--beige); */
    color: var(--darkBlue);
  }

  li {
    /* border-radius: 5px; */
    margin: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

    padding: 10px;
    list-style-type: none;

    border: 1px solid var(--orange);

    &:hover {
      cursor: pointer;
      background-color: var(--orange);
      color: var(--white);
      @media (max-width: 769px) {
        background-color: none;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  height: 70vh;

  .map {
    width: 80%;
    height: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    .map {
      width: 100%;
      height: 50%;
    }
  }
`
