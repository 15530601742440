import React, { useState } from "react"
import { graphql } from "gatsby"

import BodyWrapper from "../templates/BodyWrapper"
import { compose } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps"
import { MarkerInfo, SearchContainer, Wrapper } from "../styles/MapStyles"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import phoneIcon from "../dev_assets/Find-us/phone.png"
import webIcon from "../dev_assets/Find-us/www.png"
import {
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  // Logo9,
  Logo10,
  Logo11,
  Logo12,
} from "../utils/imageUpload"
import styled from "styled-components"

const apiKey = process.env.GATSBY_GOOGLE_MAP_API

const blankImg =
  "https://located.nyc3.cdn.digitaloceanspaces.com/located/app/images/blank_image.png"

export const IndexQuery = graphql`
  query {
    allStoreLocationsCsv {
      edges {
        node {
          Name
          Address
          Latitude
          Longitude
        }
      }
    }
  }
`
const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap defaultZoom={4} defaultCenter={{ lat: 37.5, lng: -80.165222 }}>
      {props.markers.map((marker, index) => {
        const lat = marker.Latitude.replace(",", ".")
        const lng = marker.Longitude.replace(",", ".")

        if (marker.Name === "Whole Foods Orange Village") return

        return (
          <Marker
            icon={"https://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
            onClick={() => props.onClick(marker)}
            key={index}
            position={{
              lat: Number(lat),
              lng: Number(lng),
            }}
          >
            {props.selectedMarker === marker && (
              <InfoWindow>
                <MarkerInfo>
                  <div>
                    {marker.Image_Url !== blankImg && (
                      <div
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                      </div>
                    )}
                    <p style={{ fontWeight: "bold" }}>{marker.Name}</p>
                    <p> {marker.Address}</p>
                  </div>
                  {marker.Telephone && (
                    <p>
                      <img
                        className="icon"
                        width="15px"
                        src={phoneIcon}
                        alt={marker.Name}
                      />{" "}
                      {marker.Telephone}
                    </p>
                  )}
                  {marker.Website && (
                    <OutboundLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={marker.Website}
                    >
                      <p>
                        <img
                          className="icon"
                          width="15px"
                          src={webIcon}
                          alt={marker.Name}
                        />
                        View Website
                      </p>
                    </OutboundLink>
                  )}
                </MarkerInfo>
              </InfoWindow>
            )}
          </Marker>
        )
      })}
    </GoogleMap>
  )
})

const FindUs = ({ data }) => {
  const stores = data.allStoreLocationsCsv.edges.map(({ node }) => node)

  const [locations] = useState(stores)
  const [list, setlist] = useState(stores)
  const [selectedMarker, setselectedMarker] = useState(false)
  const [search, setsearch] = useState("")

  const handleClick = (marker, event) => {
    setselectedMarker(marker)
  }

  const handleChange = (e) => {
    e.preventDefault()
    setsearch(e.target.value)

    const filtered = locations.filter((location) => {
      return (
        location.Name.toLowerCase().includes(search.toLowerCase()) ||
        location.Address.toLowerCase().includes(search.toLowerCase())
      )
    })
    setlist(filtered)
  }
  if (!(typeof window !== "undefined")) {
    return (
      <Layout>
        <h1>Loading</h1>
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO title="Find Us" />
      <Wrapper>
        <SearchContainer>
          <input
            onChange={handleChange}
            type="text"
            placeholder="Enter a location"
          />
          <ul>
            {list.map((location, index) => {
              return (
                <li key={index} onClick={() => handleClick(location)}>
                  <h5 style={{ fontWeight: "400" }}>{location.Name}</h5>
                  <h6>{location.Address}</h6>
                  {/* <h6>{location.Suite}</h6> */}
                </li>
              )
            })}
          </ul>
        </SearchContainer>
        <MapWithAMarker
          className="map"
          selectedMarker={selectedMarker}
          markers={locations}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
          onClick={handleClick}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="map" />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Wrapper>
      <BodyWrapper logos="true">
        <LogoWrapper>
          <div>
            <img src={Logo1} alt="" />
          </div>

          <div>
            <img src={Logo2} alt="" />
          </div>

          <div>
            <img src={Logo3} alt="" />
          </div>

          <div>
            <img src={Logo4} alt="" />
          </div>

          <div>
            <img src={Logo5} alt="" />
          </div>

          <div>
            <img src={Logo6} alt="" />
          </div>

          <div>
            <img src={Logo7} alt="" />
          </div>
          <div>
            <img src={Logo8} alt="" />
          </div>
          {/*    <div>
            <img src={Logo9} alt="" />
          </div> */}
          <div>
            <img src={Logo10} alt="" />
          </div>
          <div>
            <img src={Logo11} alt="" />
          </div>
          <div>
            <img src={Logo12} alt="" />
          </div>
        </LogoWrapper>
      </BodyWrapper>
    </Layout>
  )
}

export default FindUs

const LogoWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  div {
    height: 100%;
    padding: 0 10px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  img {
    width: 100%;
    margin: 0;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 10px;

  img {
    width: 100px;
    // height: 40%;
    padding: 0 4px;
  }
`
